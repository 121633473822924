<template>
  <el-dialog
    :visible.sync="visible"
    :before-close="handleClose"
    :close-on-click-modal="true"
    :close-on-press-escape="true"
    :lock-scroll="true"
    :fullscreen="fullScreen"
    :center="center"
    width="40vw"
    class="quotation-modal"
    id="quotation-modal"
    v-loading="loading"
  >
    <!-- title -->
    <span slot="title" class="dialog-header">
      <span class="el-dialog__title">
        {{ $t("request_invoice") }}
      </span>
    </span>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="top"
      v-if="!saved"
    >
      <div class="row">
        <div class="col-md-6">
          <el-form-item class="md-form" prop="firstname">
            <el-input
              v-model="form.firstname"
              :placeholder="$t('firstname')"
            ></el-input>
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item class="md-form" prop="lastname">
            <el-input
              v-model="form.lastname"
              :placeholder="$t('lastname')"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <el-form-item class="md-form" prop="email">
            <el-input
              v-model="form.email"
              :placeholder="$t('email')"
            ></el-input>
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item class="md-form" prop="phoneNumber">
            <el-input
              v-model="form.phoneNumber"
              @keypress="isPhoneNumber()"
              :placeholder="$t('phonenumber')"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <el-form-item class="md-form" prop="zipcode">
            <el-input
              v-model="form.zipcode"
              @keyup.native="zipcodetoStreetname"
              :placeholder="$t('zipcode')"
            ></el-input>
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item class="md-form" prop="housenumber">
            <el-input
              v-model.number="form.housenumber"
              :placeholder="$t('housenumber')"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="row" v-loading="zipcodeLoading">
        <div class="col-md-6">
          <el-form-item class="md-form" prop="streetname">
            <el-input
              v-model="form.streetname"
              :placeholder="$t('streetname')"
            ></el-input>
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item class="md-form" prop="city">
            <el-input v-model="form.city" :placeholder="$t('city')"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <vue-recaptcha
            ref="recpatcha"
            class="recaptcha-block"
            sitekey="6LdrKlkaAAAAAAPi1mOOqm9e4iNKM7ODStHSagi0"
            @verify="verifyRecaptcha"
          ></vue-recaptcha>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-5">
          <el-button
            @click="handleClose"
            class="button-white-slide width-100"
            >{{ $t("decline") }}</el-button
          >
        </div>
        <div class="col-lg-4 col-md-5">
          <el-button class="button-green-slide width-100" @click="submitForm">{{
            $t("accept")
          }}</el-button>
        </div>
      </div>
    </el-form>
    <div v-if="saved === 'error'" class="row">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="text-center">
          <h3>{{ $t("error_quotation_modal") }}</h3>
          <p class="width-80">{{ $t("error_quotation_modal_text") }}</p>
        </div>
      </div>
    </div>
    <div v-if="saved" class="row">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="text-center">
          <h3>{{ $t("success_quotation_modal") }}</h3>
          <p class="width-80">{{ $t("success_quotation_modal_text") }}</p>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer" v-if="saved != false">
      <el-button @click="handleClose" class="button-white-slide">{{ $t("close") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Vue from "vue";
import VueRecaptcha from "vue-recaptcha";
export default {
  name: "QuotationModal",
  components: { VueRecaptcha },
  props: {
    visible: Boolean,
    form_id: Number
  },
  data() {
    return {
      loading: false,
      saved: false,
      center: false,
      zipcodeLoading: false,
      isHuman: false,
      form: {
        form_id: 3,
        recaptchaResponse: null,
        firstname: null,
        lastname: null,
        email: null,
        phoneNumber: null,
        zipcode: null,
        housenumber: null,
        streetname: null,
        city: null,
      },
      rules: {
        firstname: [
          {
            required: true,
            message: this.$t("please_fill_a_firstname"),
            trigger: "blur",
          },
        ],
        lastname: [
          {
            required: true,
            message: this.$t("please_fill_a_lastname"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            type: "email",
            message: this.$t("please_fill_a_email"),
            trigger: "blur",
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$t("please_fill_a_phonenumber"),
            trigger: "blur",
          },
        ],
        zipcode: [
          {
            required: true,
            message: this.$t("please_fill_a_zipcode"),
            trigger: "blur",
          },
          {
            min: 4,
            max: 6,
            message: this.$t("please_fill_a_zipcode"),
            trigger: "blur",
          },
        ],
        housenumber: [
          {
            required: true,
            message: this.$t("please_fill_a_housenumber"),
            trigger: "blur",
          },
        ],
        streetname: [
          {
            required: true,
            message: this.$t("please_fill_a_streetname"),
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: this.$t("please_fill_a_city"),
            trigger: "blur",
          },
        ],
        recaptchaResponse: [
          {
            required: true,
            message: this.$t("please_fill_in_recaptcha"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.form.form_id = this.form_id;
  },
  watch: {},
  computed: {
    fullScreen: function () {
      return window.outerWidth <= 767;
    },
  },
  methods: {
    async zipcodetoStreetname() {
      if (this.form.zipcode == null || this.form.zipcode.length != 6)
        return false;

      this.zipcodeLoading = true;
      if (this.form.housenumber != null) {
        var response = await Vue.http.get(
          "https://api.pro6pp.nl/v1/autocomplete?auth_key=WM7MZftFShzw23Le&nl_sixpp=" +
            this.form.zipcode +
            "&streetnumber=" +
            this.form.housenumber
        );
      } else {
        var response = await Vue.http.get(
          "https://api.pro6pp.nl/v1/autocomplete?auth_key=WM7MZftFShzw23Le&nl_sixpp=" +
            this.form.zipcode
        );
      }
      if (response.data.status === "ok") {
        this.form.city = response.data.results[0].city;
        this.form.streetname = response.data.results[0].street;
        this.zipcodeLoading = false;
        return true;
      } else return false;
    },
    verifyRecaptcha(response) {
      this.form.recaptchaResponse = response;
    },

    isPhoneNumber() {
      if (this.form.phoneNumber.length > 9) {
      }
    },
    submitForm() {
      let self = this;
      this.loading = true;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (self.form.recaptchaResponse != null) {
            Vue.http
              .post("/estimate/sendEstimate", this.form)
              .then(function (response) {
                if (response) {
                  self.loading = false;
                  self.center = true;
                  self.saved = true;
                } else {
                  self.loading = false;
                  self.center = true;
                  self.saved = "error";
                }
              });
          } else console.log("Please fill in the reCAPTCHA");
        } else console.log("error submit!!");
      });
      this.loading = false;
    },
    handleClose() {
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style lang="scss">
$green: #3fa435;
$dark-green: #529139;
$black: black;
$background-color: #282827;
$white: white;
.quotation-modal {
  .el-dialog{
    @media (max-width: 768px) {
      width: 90%!important;
    }
  }
  .el-button {
    padding: 10px 15px;
    line-height: 16px;
    font-size: 16px;
    font-weight: 600;

    &.button-green-slide {
      background: $green;
      border: none;
      border-bottom: 3px solid $dark-green;
      text-align: center;
      color: #fff;
      cursor: pointer;
      &:hover {
        background-position: left bottom;
        border-bottom: 3px solid $black;
      }
    }

    &.button-white-slide {
      color: $black;
      border: 2px solid $black;
      text-align: center;
      cursor: pointer;
      &:hover {
        border: 2px solid $black;
        background-position: left bottom;
        color: $white;
      }
    }
  }
}
</style>