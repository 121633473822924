<template>
  <li class="site-language hidden-xs">
    <el-select
        class="change-language-select"
        popper-class="switch-locale-popper"
        :value="language"
        @change="changeLocale"
        value-key="language_code"
        :style="'width: ' + (language.language.length + 2) + 'ch;'"
    >
      <el-option
          v-for="(language, key) in languages"
          :key="language.id"
          :value="language"
          :label="language.language"
      >  
        <country-flag
            :country="language.language_code"
            size="small"
        />
        <p v-if="language && language.language_code" class="lang-text">
            {{ language.language }}
        </p>
      </el-option>
    </el-select>
    <div class="hidden-lang-placeholder">
      <span>{{language.language}}</span>
      <i class="globe-icon fal fa-globe-europe"></i>
    </div>
  </li>
</template>

<script>
import Vue from "vue";
import CountryFlag from "vue-country-flag";

export default {
  name: "ChangeLanguage",
  components: {
    CountryFlag,
  },
  props: {
    translation: Object,
    languages: Array,
    language: Object,
  },
  methods: {
    changeLocale(value) {
      this.redirectLocale(value);
    },
    redirectLocale(value) {
      window.location.href = "https://" + value.domain;
    }
  }
}
</script>

<style lang="scss">
@import "../../plugins/Bootstrap-sass/bootstrap/media";
@import "../../plugins/Bootstrap-sass/bootstrap/variables";

.hidden-lang-placeholder {
  text-align: right;
  position: relative;
  float:right;

  span{
    visibility: hidden;
  }
  
  .globe-icon {
    color: #282827;
    margin-top: 2px;
    font-size: 20px;
    position: absolute;
    left: -30px;
    top: -25px;
  }
}

.change-language-select {
    display: block!important;
    .el-input {
        font-size: 14px;
    }
    input {
        border: none;
        max-width: 100%;
        caret-color: transparent; 
        padding: 0!important;
        background: transparent;
        font-size: 14px!important;
        height: 26px!important;
        font-family: museo-sans, sans-serif;
        text-align: right;
        color: #282827;
    }
    .el-input__suffix {
        display: none;
    }
}

.lang-text {
    display: inline-block;
    margin-bottom: 0;
}

.switch-locale-popper {
  @media screen and (max-width: $screen-xs-max) {
    width: 100%;
    height: 100%;
    z-index: 2009;
    top: 0 !important;
    left: 0 !important;
    margin-top: 0 !important;

    .el-select-dropdown {
      &__wrap {
          max-height: initial;
      }
      &__item {
          border-bottom: 1px solid #eee;
          padding: 19px 0;
          font-size: 20px;
          line-height: 20px;
          height: auto;

          .lang-text {
              display: inline-block;
              font-size: 1em;
              line-height: 1em;
          }

          .small-flag {
              margin: -0.9em 0em -0.5em 0em;
              transform: scale(0.5);
              width: 52px;
              height: 37px;
          }

        &.go-back {
            color: #606266;
        }
      }
    }
  }
}
</style>
