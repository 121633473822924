<template>
    <div id="mob-search">
        <el-select
            v-if="!mobileView"
            id="search"
            ref="desktop-search"
            v-model="result"
            :placeholder="$t('what_product_are_you_looking_for')"
            filterable
            remote
            loading-text=" "
            :no-match-text="$t('no_results')"
            :no-data-text="$t('no_results')"
            popper-class="dropdown-top-search"
            :popper-append-to-body="false"
            :remote-method="querySearchAsync"
            :loading="isLoading"
            @change="handleSelect"
            @focus="openMobileSearchScreen"
        ></el-select>

        <label for="search">
            <i class="far fa-search"></i>
        </label>

        <el-dialog
            :fullscreen="true"
            :visible.sync="mobileView"
            :show-close="false"
            :append-to-body="true"
            custom-class="mobile-view"
            
        >
            <div class="go-back" @click="closeMobileView">
                <i class="fa fa-chevron-left"></i>
            </div>
            <div class="clear-button" @click="clearSearch">
                <i class="fa fa-times-circle"></i>
            </div>

            <el-select
                ref="mobile-search"
                id="mobile-search"
                v-model="result"
                :placeholder="$t('what_product_are_you_looking_for')"
                filterable
                remote
                clearable
                loading-text=" "
                :no-match-text="$t('no_results')"
                :no-data-text="$t('no_results')"
                popper-class="dropdown-top-search"
                :popper-append-to-body="false"
                :remote-method="querySearchAsync"
                :loading="isLoading"
                @change="handleSelect"
            >
                <el-option-group
                    v-for="(result_group, key) in results"
                    :key="$t(key)"
                    :label="$t(key)"
                >
                    <el-option
                        v-for="(result, r_key) in result_group"
                        :key="key+r_key"
                        :value="{ type: key, result: result }"
                    >
                        <template v-if="key == 'products'"
                            ><strong>{{ result.nd_id }}</strong> | {{ result.description }}</template
                        >
                        <template v-if="key == 'brands'">
                            {{ result.brand_name }}
                        </template>
                        <template v-if="key == 'series'">
                            {{ result.serie_name }}
                        </template>
                    </el-option>
                </el-option-group>
            </el-select>
        </el-dialog>
    </div>
</template>
  
<script>
import Vue from "vue";
  export default {
    name: "MobSearch",
    components: {},
    props: {
      translation: Object,
      languages: Array,
      language: Object,
    },
    data() {
        return {
            results: [],
            result: null,
            isLoading: false,
            mobileView: false,
        }
    },
    mounted() {},
    methods: {
        querySearchAsync: async function(queryString) {
            this.isLoading = true;
            let response = await Vue.http.get("/search/all?q=" + queryString);

            if (response && response.data) {
                this.results = response.data.hits;
                this.isLoading = false;
            }
        },
        handleSelect: async function(item) {
            if (item.type === "products") {
                location.assign("/winkel/" + item.result.url);
            } else if (item.type === "brands") {
                location.assign("/merken/" + item.result.brand_url);
            } else if (item.type === "series") {
                location.assign("/" + item.result.serie_url);
            }
        },
        openMobileSearchScreen: function() {
            if (window.innerWidth <= 767) {
                this.mobileView = true;

                setTimeout(() => {
                    if(this.$refs['mobile-search']){
                        this.$refs['mobile-search'].focus();

                        $("#chat-widget-container").toggleClass("hidden");
                    }
                }, 100);
            }
        },
        closeMobileView: function() {
            this.result = "";
            this.mobileView = false;
            this.results = [];
        },
        clearSearch: function() {
            this.result = "";
            this.results = [];
        }
    }
  }
</script>
  
<style lang="scss">
  #mob-search {
    label[for="search"] {
        border: 0;
        z-index: 2;
        width: 41px;
        height: 100%;
        display: flex;
        font-size: 20px;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        background: transparent;
        transition: .25s ease-in-out;
        color: #3FA435;
        margin-bottom: 0;
        position: absolute;
        top: 0;
        right: 20px;
    }

    .el-select {
        width: 100%;
        .el-input__suffix {
            display: none;
        }
        input {
            width: 100%;
            outline: 0;
            height: 40px!important;
            font-size: 14px!important;
            box-shadow: none;
            outline-offset: 0;
            border-radius: 20px;
            background: #f8f7f7;
            color: #4e5055;
            border: 1px solid transparent;
            transition: 0.25s ease-in-out;
            font-family: 'museo-sans';
            position: relative;
            padding-left: 20px!important;
            padding-right: 0;
            border: none;
            background-color: #eaeaea;
            &::placeholder {
                color: #4e5055;
            }
        }
    }
    .dropdown-top-search {
        width: 100%;
    }
}
</style>
  