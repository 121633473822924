<template>
  <div>
    <h3>{{ $t("company_info") }}</h3>
    <div class="bg-wrapper background width-100">
      <div class="row">
        <div class="col-md-12">
          <h5>{{ $t("company") }}</h5>
        </div>
      </div>
      <div class="row">
        <div class="hidden-xs col-sm-5 col-md-4">
          <div class="list-item">{{ $t("companyname") }}:</div>
        </div>
        <div class="col-xs-12 col-sm-7 col-md-8">
          <div class="list-item">{{ company_name }}</div>
        </div>
      </div>
      <div class="row">
        <div class="hidden-xs col-sm-5 col-md-4">
          <div class="list-item">{{ $t("client_number") }}:</div>
        </div>
        <div class="col-xs-12 col-sm-7 col-md-8">
          <div class="list-item">{{ client_number }}</div>
        </div>
      </div>
      <div class="row">
        <div class="hidden-xs col-sm-5 col-md-4">
          <div class="list-item">{{ $t("contact_person") }}</div>
        </div>
        <div class="col-xs-12 col-sm-7 col-md-8">
          <div class="list-item">
            {{ client_contact["firstname"] }} {{ client_contact["lastname"] }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="hidden-xs col-sm-5 col-md-4">
          <div class="list-item">{{ $t("email") }}:</div>
        </div>
        <div class="col-xs-12 col-sm-7 col-md-8">
          <div class="list-item">{{ client_contact["email"] }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="list-item">
            <h5>{{ $t("invoice_address") }}</h5>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="list-item">
            {{ billing_address["name"] }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="list-item">
            {{ billing_address["Address"] }}
          </div>
        </div>
      </div>
      <div class="row" v-if="billing_address['Address_2']">
        <div class="col-md-12">
          <div class="list-item">
            {{ billing_address["Address_2"] }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="list-item">
            {{ billing_address["Post_Code"] }} {{ billing_address["City"] }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="list-item">
            {{ $t(billing_address["Country_Region_Code"]) }}
          </div>
        </div>
      </div>
    </div>
    <div class="bg-wrapper background width-100">
      <div class="row">
        <div class="col col-md-8">
          <div class="company-text">
            {{ $t("company_info_not_up_to_date") }}
          </div>
          <div class="company-text">{{ $t("let_us_know") }}</div>
        </div>
        <div class="col col-md-4">
          <div class="company-button small-margin-top-20">
            <button
              type="button"
              class="button-green-slide width-100"
              @click="editCompany"
            >
              Wijziging indienen &nbsp;
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible="dialogFormVisible"
      :before-close="closeCompany"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      :lock-scroll="true"
      :fullscreen="checkFullscreen"
      :loading="loading"
      :title="$t('create_change')"
      class="company-modal"
    >
      <el-form
        class="form-group"
        ref="form"
        :model="form"
        :rules="rules"
        label-position="top"
        v-if="!saved"
      >
        <div class="row">
          <div class="col-xs-12">
            <el-form-item class="md-form" prop="note">
              <el-input
                :placeholder="$t('changes')"
                v-model="form.note"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <el-form-item class="md-form">
              <el-select
                v-model="form.new_address"
                :placeholder="$t('default_supply_address')"
                class="width-100"
                v-if="client_addresses"
              >
                <el-option
                  :key="index"
                  v-for="(option, index) in client_addresses"
                  :label="
                    option['Name'] +
                    ' - ' +
                    option['Address'] +
                    ' ' +
                    formatCity(option['City'])
                  "
                  :value="
                    option['Name'] +
                    ' - ' +
                    option['Address'] +
                    ' ' +
                    formatCity(option['City'])
                  "
                ></el-option></el-select
            ></el-form-item>
          </div>
        </div>
      </el-form>
      <div v-else>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <h4 class="bold">{{ $t("changes_saved") }}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="company-text">
              {{ $t("changes_saved_text") }}
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer modal-footer">
          <div class="row">
            <div class="col-xs-6 col-sm-4 col-md-4">
              <button
                @click="closeCompany"
                type="button"
                class="button-white-slide width-100"
              >
                {{ $t("close") }}
              </button>
            </div>
          </div>
        </span>
      </div>
      <span slot="footer" class="dialog-footer modal-footer" v-if="!saved">
        <div class="row">
          <div class="col-xs-6 col-sm-4 col-md-4">
            <button
              @click="saveCompany"
              type="submit"
              class="button-green-slide width-100"
            >
              {{ $t("sent") }}
            </button>
          </div>
          <div class="col-xs-6 col-sm-4 col-md-4">
            <button
              @click="closeCompany"
              type="button"
              class="button-white-slide width-100"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "EditCompany",
  props: {
    company_name: String,
    client_number: String,
    client_contact: Object,
    client_addresses: Array | String,
    billing_address: Object | String,
    translation: Object,
  },
  data() {
    return {
      dialogFormVisible: false,
      loading: false,
      saved: false,
      form: {
        client_number: this.client_number,
        note: "",
        new_address:(this.client_addresses ?
          this.client_addresses[0]["Name"] +
          " - " +
          this.client_addresses[0]["Address"] +
          " " +
          this.formatCity(this.client_addresses[0]["City"]) : ""),
      },
      rules: {
        note: [
          {
            required: true,
            message: this.$t("please_fill_a_change_note"),
            trigger: "blur",
          },
        ],
        new_address: [
          {
            required: true,
            message: this.$t("please_fill_a_shipping_address"),
            trigger: "blur",
          },
        ],
        client_number: [
          {
            required: true,
            hidden: true,
          },
        ],
      },
    };
  },
  computed: {
    checkFullscreen() {
      return window.outerWidth <= 767;
    },
  },
  methods: {
    formatCity(city) {
      let city1, city2;
      city1 = city.toLowerCase();
      city2 = city1.charAt(0).toUpperCase() + city1.slice(1);
      return city2;
    },
    editCompany() {
      this.dialogFormVisible = true;
    },
    closeCompany() {
      this.dialogFormVisible = false;
    },
    saveCompany() {
      this.loading = true;
      let self = this;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          Vue.http
            .post("/account/editCompany", this.form)
            .then(function (response) {
              if (response) {
                self.loading = false;
                self.saved = true;
                return true;
              }
            });
        } else {
          self.loading = false;
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>
