<template>
  <section id="shopping-cart">
    <div class="container" v-loading="loading">
      <div class="row overlay-background-mobile">
        <div class="col-xs-12 col-md-12">
          <div
            class="bg-wrapper overlay-background width-100"
            v-if="products != false"
          >
            <h3 v-if="isLoggedIn">{{ $t("cart") }}</h3>
            <h3 v-else>{{ $t("estimate_list") }}</h3>
            <div class="row table-head hidden-xs hidden-sm">
              <div class="col-md-2">
                <h5 class="text-left">
                  {{ $t("photo") }}
                </h5>
              </div>
              <div class="col-md-4">
                <h5 class="text-left">{{ $t("product") }}</h5>
              </div>
              <div class="col-md-1">
                <h5 class="text-left">{{ $t("amount") }}</h5>
              </div>
              <div class="col-md-2">
                <h5 v-if="isLoggedIn" class="text-right">
                  {{ $t("discount") }}
                </h5>
              </div>
              <div class="col-md-2">
                <h5 v-if="isLoggedIn" class="text-right">
                  {{ $t("price") }}
                </h5>
              </div>
              <div class="col-md-1 text-center">
                <h5 class="text-center">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </h5>
              </div>
            </div>
            <hr class="black-background" />
            <div class="row" :key="index" v-for="(product, index) in products">
              <div class="hidden-xs hidden-sm col-md-2">
                <div class="img-class">
                  <img
                    v-if="product.image"
                    :src="product.image"
                    :title="'photo_' + product.article_number"
                  />
                  <img
                    v-else-if="!product.fixed"
                    src="https://www.nedelko.be/images/afbeelding-niet-gevonden.jpg"
                  />
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                <div class="list-item green-text line-height-sm bold">
                  {{ product.name }}&nbsp;<i
                    class="fas fa-info-circle mouse-pointer"
                    v-if="!product.fixed"
                    @click="showOptions(index)"
                  ></i>
                </div>
                <div
                  :class="{ active: index === activeOptions }"
                  class="bg-wrapper background hide"
                >
                  <div :key="index" v-for="(option, index) in product.options">
                    <div class="row">
                      <div class="col-xs-6 col-sm-6 col-md-6">
                        <div class="list-item">{{ option.option_label }}:</div>
                      </div>
                      <div class="col-xs-6 col-sm-6 col-md-6">
                        <div class="list-item">{{ option.value }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="margin-top-20" v-if="!product.fixed">
                  <div class="row">
                    <div class="col-xs-7 col-sm-3 col-md-4">
                      <div class="grey-text">{{ $t("supply_amount") }}:</div>
                    </div>
                    <div class="col-xs-5 col-sm-5 col-md-5">
                      <div class="grey-text text-right">
                        {{ product.user_unit }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-7 col-sm-3 col-md-5">
                      <div class="grey-text">{{ $t("article_number") }}:</div>
                    </div>
                    <div class="col-xs-5 col-sm-5 col-md-4">
                      <div class="grey-text text-right">
                        {{ product.article_number }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="margin-top-20">
                  <div class="row">
                    <div class="col-xs-10 col-sm-8 col-md-10">
                      <button
                        v-if="!product.fixed && isLoggedIn"
                        class="width-100 stock-button"
                        @click="toggle(index)"
                      >
                        <template>
                          <div
                            class="wrapper"
                            v-if="stock_info && stock_info[index] ? stock_info[index] : false"
                          >
                            <div class="row">
                              <div
                                :class="{
                                  'inStock': stock_info[index].AvailableInventory >= product.qty,
                                  'partiallyInStock': stock_info[index].AvailableInventory > 0 && stock_info[index].AvailableInventory < product.qty,
                                  'outOfStock': stock_info[index].AvailableInventory <= 0
                                }"
                                
                              >
                                <div class="col-xs-2 col-sm-2 col-md-2">
                                  <i class="fas fa-circle"></i>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 text-left"
                                >
                                  <span v-if="stock_info[index].AvailableInventory >= product.qty">{{ $t("in_stock") }}</span>
                                  <span v-if="stock_info[index].AvailableInventory > 0 && stock_info[index].AvailableInventory < product.qty">{{ $t("partially_in_stock") }}</span>
                                  <span v-if="stock_info[index].AvailableInventory <= 0">{{ $t("not_in_stock") }}</span>
                                </div>
                              </div>
                              <div class="col-xs-2 col-sm-2 col-md-2">
                                <i
                                  id="toggle-button-chevron"
                                  class="fas"
                                  :class="
                                    activeItem === index
                                      ? 'fa-chevron-up green-icon'
                                      : 'fa-chevron-down'
                                  "
                                ></i>
                              </div>
                            </div>
                            <div
                              :class="{ active: index === activeItem }"
                              class="hide"
                            >
                              <div
                                v-if="stock_info[index].AvailableInventory >= product.qty"
                              >
                                <div class="margin-top-10 list-item text-left">
                                  {{ $t("from_stock") }}: {{ product.qty }}
                                </div>
                                <div class="list-item text-left">
                                  {{ $t("delivery_time") }}: 1 Werkdag (Voor 15:00 besteld)
                                </div>
                              </div>
                              <div
                                v-else-if="stock_info[index].AvailableInventory > 0 && stock_info[index].AvailableInventory < product.qty"
                              >
                                <div class="margin-top-10 list-item text-left">
                                  {{ $t("from_stock") }}: {{ stock_info[index].AvailableInventory }}
                                </div>
                                <div class="margin-top-10 list-item text-left">
                                  {{ $t("from_backorder") }}: {{ product.qty - stock_info[index].AvailableInventory }}
                                </div>
                                <div class="list-item text-left">
                                  {{ $t("delivery_time") }}: {{stock_info[index].LeadTimeCalculation}}
                                </div>
                              </div>  
                              <div
                                v-else-if="stock_info[index].AvailableInventory <= 0"
                              >
                                <div class="margin-top-10 list-item text-left">
                                  {{ $t("from_backorder") }}: {{ product.qty }}
                                </div>
                                <div class="list-item text-left">
                                  {{ $t("delivery_time") }}: {{stock_info[index].LeadTimeCalculation}}
                                </div>
                              </div>                      
                            </div>
                          </div>
                          <div class="wrapper" v-else>
                            {{ $t("error_in_api_short") }}
                          </div>
                        </template>
                        <template v-if='1==2'>
                          <div class="wrapper">
                            {{ $t("on_request") }}
                          </div>
                        </template>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-1">
                <div class="margin-top-20-mobile">
                  <input
                    v-if="!product.fixed"
                    @change="(event) => updateItem(product.key, product.qty)"
                    class="amount-item text mouse-pointer"
                    v-model="product.qty"
                  />
                </div>
              </div>
              <div class="hidden-xs hidden-sm col-md-2">
                <template v-if="!product.fixed">
                  <div
                    class="list-item text-right"
                    v-if="isLoggedIn && product.discount_percentage != 0"
                  >
                    -{{ product.discount_percentage }}%
                  </div>
                  <div class="list-item text-right" v-else-if="isLoggedIn">-</div>
                  <div v-else>{{ $t("on_request") }}</div>
                </template>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 text-right">
                <div class="margin-top-20-mobile">
                  <div v-if="isLoggedIn" class="list-item">
                    {{ formatPrice(product.sub_total) }}
                  </div>
                </div>
              </div>
              <div v-if="!product.fixed"
                class="col-xs-12 col-sm-12 col-md-1 text-center mouse-pointer"
              >
                <i
                  @click="deleteItem(product.key)"
                  class="fa fa-times rounded-circle hidden-xs hidden-sm"
                  aria-hidden="true"
                ></i>

                <a
                  @click="deleteItem(product.key)"
                  class="hidden-md hidden-lg mouse-pointer"
                >
                  <div
                    class="
                      list-item
                      green-text
                      padding-top-10 padding-bottom-10
                      text-left
                      margin-top-20-mobile
                    "
                  >
                    <i class="fa fa-trash" aria-hidden="true"></i>&nbsp;
                    {{ $t("delete_from_shopping_cart") }}
                  </div>
                </a>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12">
                <hr />
              </div>
            </div>
          </div>
          <!-- empty cart -->
          <div v-else class="bg-wrapper overlay-background width-100">
            <h3>
              {{ isLoggedIn ? $t("empty_cart_title") : $t("estimate_list") }}
            </h3>
            <a href="/winkel">
              <p>
                {{ isLoggedIn ? $t("empty_cart_text") : $t("empty_estimate_text") }}
              </p>
            </a>
          </div>
        </div>
      </div>
      <!-- Pricing underneath -->
      <div class="row overlay-background-mobile" v-if="this.products != false">
        <div class="col-xs-12 col-md-12">
          <div class="row">
            <div class="col-md-8"></div>
            <div class="col-xs-12 col-sm-12 col-md-4">
              <div class="bg-wrapper overlay-background width-100">
                <div v-if="isLoggedIn" class="row total-prices">
                  <div class="col-xs-6 col-sm-7 col-md-8">
                    <span>{{ $t("order_amount") }}</span>
                    <span>{{ $t("sub_total") }}</span>
                    <span>{{ $t("tax_amount") }}</span>
                    <span class="bold">{{ $t("order_amount_inc_btw") }}</span>
                  </div>
                  <div class="col-xs-6 col-sm-5 col-md-4">
                    <div class="text-right">
                      <span>{{ formatPrice(estimate.grand_total) }}<br /></span>
                      {{formatPrice(estimate.grand_total) }}<br />
                      {{ formatPrice(estimate.grand_total * 0.21)}}<br />
                      <span class="bold">{{formatPrice(estimate.grand_total * 1.21) }}</span>
                    </div>
                  </div>
                </div>
                <div class="row margin-top-20">
                  <div class="col-xs-12 col-md-12">
                    <a v-if="isLoggedIn" href="/offertelijst/checkoutDetails">
                      <button
                        type="button"
                        class="
                          button-green-slide
                          chevron
                          width-100
                          checkout-button
                        "
                      >
                        {{ $t("continue_to_order") }}
                      </button>
                    </a>
                    <a v-if="!isLoggedIn"  @click="quotationModalVisible = true">
                      <button
                        type="button"
                        class="button-black width-100 checkout-button"
                      >
                        {{ $t("request_invoice") }}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <QuotationModal :visible.sync="quotationModalVisible" :form_id="form_id"></QuotationModal>
  </section>
</template>

<script>
import Vue from "vue";
import QuotationModal from "./partials/QuotationModal.vue";

export default {
  name: "Checkout",
  components: { QuotationModal },
  props: {
    translation: Object,
    form_id: Number
  },
  data() {
    return {
      loading: false,
      estimate: false,
      products: false,
      stock_info: false,
      activeItem: false,
      activeOptions: false,
      quotationModalVisible: false,
      isLoggedIn: false,
    };
  },
  async mounted() {
    await this.getItems();
    await this.getStockInfo();
  },
  methods: {
    formatPrice(price) {
      if (this.isLoggedIn !== false || price !== "Op aanvraag") {
        return Intl.NumberFormat("nl-NL", {
          style: "currency",
          currency: "EUR",
        }).format(price);
      }
    },
    formatDate(date) {
      if (date != false) {
        if (!date === "Vandaag") {
          return new Date(date)
            .toLocaleDateString()
            .replace(new RegExp("/"), "-");
        } else {
          return "today";
        }
      } else {
        return "on_request";
      }
    },
    async getItems() {
      this.loading = true;
      let response = await Vue.http.get("/offertelijst/get");
      if (!response || !response.body.estimate) {
        this.loading = false;
        return false;
      }
      this.estimate = response.body.estimate;
      this.products = response.body.estimate.items;
      this.isLoggedIn = response.body.isLoggedIn;
      this.loading = false;
    },

    async updateItem(id, qty) {
      this.loading = true;
      qty = parseInt(qty);
      let response = await Vue.http
        .post("/offertelijst/update", {
          id: id,
          qty: qty,
        })
        .catch(function (e) {
          return false;
        });
      if (!response) this.estimate = false;
      this.loading = false;
      this.getItems();
      this.getStockInfo();
    },

    async getStockInfo() {
      this.loading = true;
      let response = await Vue.http
        .post("/estimate/getStockInfo",
        {
          items: this.products,
        })
        .catch(function (e) {
          return false;
        });
      if (!response) {
        this.stock_info = false;
        // return false;
      }
      this.stock_info = response.data;
      this.loading = false;
    },
    async getPimProduct() {
      this.loading = true;
      let response = await Vue.http
        .get("/estimate/getPimProduct")
        .catch(function (e) {
          return false;
        });
      if (!response) {
        this.price = false;
        return false;
      }
      this.price = response.data;
      console.log(this.price);
      this.loading = false;
    },

    async deleteItem(id) {
      this.loading = true;
      let response = await Vue.http
        .post("/offertelijst/delete", {
          id: id,
        })
        .catch(function (e) {
          return false;
        });
      if (!response) this.estimate = false;
      this.loading = false;
      this.getItems();
      this.getStockInfo();
    },

    toggle(index) {
      if (this.activeItem === index) {
        this.activeItem = false;
      } else {
        this.activeItem = index;
      }
    },

    showOptions(index) {
      if (this.activeOptions === index) {
        this.activeOptions = false;
      } else {
        this.activeOptions = index;
      }
    },
  },
};
</script>
<style lang="scss">
#shopping-cart .show-options-button {
  border: none;
  padding: 5px;
}
#shopping-cart .discounted-price {
  text-decoration: line-through;
}
#shopping-cart .discount-percentage {
  color: #ec641a !important;
}
#shopping-cart .total-prices span {
  display: block;
}
#shopping-cart .total-prices {
  font-size: 1rem;
  line-height: 24px;
  color: #282827;
  font-weight: 500;
}

.inStock {
  div {
    .fa-circle {
      color: #3fa435;
    }
  }
}
.partiallyInStock {
  div {
    .fa-circle {
      color: #ec641a;
    }
  }
}
.outOfStock {
  div {
    .fa-circle {
      color: red;
    }
  }
}
</style>