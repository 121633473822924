<template>
  <div id="editUser">
    <h3>{{ $t("users") }}</h3>
    <div class="bg-wrapper background width-100">
      <div class="row table hidden-xs hidden-sm">
        <div class="col-md-3">
          <div class="list-item">
            <h5>{{ $t("username") }}</h5>
          </div>
        </div>
        <div class="col-md-4">
          <div class="list-item">
            <h5>{{ $t("email") }}</h5>
          </div>
        </div>
        <div class="col-md-3">
          <div class="list-item">
            <h5>{{ $t("roll") }}</h5>
          </div>
        </div>
      </div>
      <div :key="index" v-for="(client, index) in clients" class="row table">
        <div class="col-xs-8 col-sm-8 col-md-3">
          <div class="list-item">
            {{ client.login_name }}
          </div>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-4">
          <div class="list-item">
            {{ client.email }}
          </div>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-3">
          <div class="list-item">
            {{ client.role == "admin" ? $t("admin") : $t("user") }}
          </div>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-2">
          <div
            @click="editUser(client.id)"
            class="list-item user-icon text-center"
          >
            <i class="fal fa-pencil" aria-hidden="true"></i>
          </div>
          <a
            :href="'/account/deleteUser/' + client.id"
            class="list-item user-icon text-center"
            onclick="return confirm('Weet u het zeker?'); return false;"
          >
            <i class="fal fa-trash" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <hr class="hidden-md hidden-lg" />
    </div>
    <div class="row">
      <div class="col-xs-12 col-md-5">
        <div class="user-button">
          <button type="button" class="button-green-slide" @click="addUser">
            <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
            {{ $t("add_user") }}
          </button>
        </div>
        <el-dialog
          class="estimate-modal"
          :title="$t('edit_user')"
          :visible.sync="dialogFormVisible"
          :fullscreen="checkFullscreen()"
        >
          <h6 v-if="!submitted">
            {{ $t("add_user_details") }}
          </h6>
          <hr v-if="!submitted" />
          <div class="row" v-if="submitted">
            <div class="col-xs-12">
              {{ $t("user_updated") }}
            </div>
          </div>
          <el-form
            class="form-group"
            ref="form"
            :model="form"
            :rules="rules"
            label-position="top"
            v-if="!submitted"
          >
            <div class="row">
              <div class="col-xs-12">
                <el-form-item class="md-form" prop="firstname">
                  <el-input
                    :placeholder="$t('firstname')"
                    v-model="form.firstname"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-xs-12">
                <el-form-item class="md-form" prop="lastname">
                  <el-input
                    :placeholder="$t('lastname')"
                    v-model="form.lastname"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <el-form-item class="md-form" prop="login_name">
                  <el-input
                    :placeholder="$t('loginname')"
                    v-model="form.login_name"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <el-form-item class="md-form" prop="password">
                  <el-input
                    type="password"
                    :placeholder="$t('password_input_label')"
                    v-model="form.password"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <el-form-item class="md-form" prop="email">
                  <el-input
                    :placeholder="$t('email')"
                    v-model="form.email"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <el-form-item class="md-form" prop="role">
                  <el-select v-model="form.role" :placeholder="$t('roll')">
                    <el-option :label="$t('user')" value="user"></el-option>
                    <el-option :label="$t('admin')" value="admin"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </el-form>
          <span
            slot="footer"
            class="dialog-footer modal-footer"
            v-if="!submitted"
          >
            <div class="row">
              <div class="col-xs-6 col-sm-4 col-md-4">
                <button
                  :loading="loading"
                  @click="saveUser"
                  type="submit"
                  class="button-green-slide width-100"
                >
                  {{ $t("sent") }}
                </button>
              </div>
              <div class="col-xs-6 col-sm-4 col-md-4">
                <button
                  @click="dialogFormVisible = false"
                  type="button"
                  class="button-white-slide width-100"
                >
                  {{ $t("cancel") }}
                </button>
              </div>
            </div>
          </span>
          <span slot="footer" class="dialog-footer" v-if="submitted">
            <button
              @click="dialogFormVisible = false"
              type="button"
              class="button-white-slide width-100"
            >
              {{ $t("close") }}
            </button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "EditUser",
  props: ["user_id", "clients", "translation"],
  data() {
    return {
      dialogFormVisible: false,
      form: {
        firstname: "",
        lastname: "",
        login_name: "",
        password: "",
        email: "",
        role: "",
      },
      user_id: false,
      rules: {
        firstname: [
          {
            required: true,
            message: this.$t("please_fill_a_firstname"),
            trigger: "blur",
          },
        ],
        lastname: [
          {
            required: true,
            message: this.$t("please_fill_a_lastname"),
            trigger: "blur",
          },
        ],
        login_name: [
          {
            required: true,
            message: this.$t("please_fill_a_loginname"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("please_fill_an_email"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("fill_a_valid_email"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("please_fill_a_password"),
            trigger: "blur",
          },
        ],
      },
      loading: false,
      submitted: false,
    };
  },
  computed: {},
  mounted() {
    if (this.user_id) {
      Vue.http
        .get("/account/getUser/" + this.user_id, self.form)
        .then(function (response) {
          if (response.data && response.data) {
            console.log(response.data);
          }
        });
    }
  },
  methods: {
    saveUser() {
      let self = this;
      this.loading = true;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          Vue.http
            .post(
              "/account/editUser" + (self.user_id ? "/" + self.user_id : ""),
              self.form
            )
            .then(function (response) {
              if (response.data && response.data.status) {
                location.reload();
              }
            });
        } else {
          self.loading = false;
          return false;
        }
      });
    },
    addUser() {
      this.dialogFormVisible = true;
      this.user_id = false;
      this.form = {
        firstname: "",
        lastname: "",
        login_name: "",
        password: "",
        email: "",
        role: "",
      };
    },
    editUser(user_id = false) {
      let self = this;
      Vue.http
        .get("/account/getUser/" + user_id, self.form)
        .then(function (response) {
          if (response.data) {
            self.dialogFormVisible = true;
            self.form.firstname = response.data.firstname;
            self.form.lastname = response.data.lastname;
            self.form.login_name = response.data.login_name;
            self.form.email = response.data.email;
            self.form.role = response.data.role;
            self.user_id = user_id;
          }
        });
    },
    checkFullscreen() {
      return window.screen.width <= 992;
    },
  },
};
</script>

<style>
#editUser .list-item {
  cursor: pointer;
}
#editUser .md-form .el-select {
  width: 100%;
}
#editUser .md-form {
  margin-bottom: 0 !important;
  margin-top: 1rem !important;
}
#editUser .md-form input {
  font-size: 16px !important;
}
#editUser .md-form input::placeholder {
  font-family: museo-sans, sans-serif;
  color: #757575;
  font-size: 15px;
  font-weight: 700;
}
</style>
