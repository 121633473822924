<template>
    <li id="offertelijst" class="offertelijst">
        <a href="offertelijst">
            <i class="fal fa-shopping-cart">
              <span class="item-count" v-if="itemCount">{{itemCount}}</span>
            </i>             
            <span class="hidden-xs hidden-sm hidden-md">
              <template v-if="isLoggedIn">
                {{translation['shopping_cart']}}
              </template>
              <template v-else>
                {{translation['my_estimate_list']}}
              </template>
            </span>
        </a>
    </li>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "HeaderCart",
  props: {
    translation: Object,
  },
  mounted() {
    this.$store.dispatch('header_cart/getItems');
  },
  computed: {
    ...mapState('header_cart', {
        itemCount: state => state.itemCount,
        isLoggedIn: state => state.isLoggedIn
    }),
  }
};
</script>

<style lang="scss">
#offertelijst{
  .fa-shopping-cart{
    position: relative;
  }
  .item-count{
    background: #3FA435;
    color: #fff;
    border-radius: 50%;
    padding: 2px 0px;
    text-align: center;
    font-size: 8px;
    position: absolute;
    top: 0;
    height: 12px;
    width: 12px;
    right: 0;
    font-family: 'museo-sans';
    top: -6px;
  }

  a{
    overflow:visible!important;
  }
}
</style>